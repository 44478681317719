import './Verifier.css'
import { Box, Container, Grid } from '@mui/material';
import VerifierForm from '../components/Verifierform';
import { useState } from 'react';

const Verifier = (props) => {
  const [values, setValues] = useState({
    checkins:[]
  });

  return(
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg" className='Verifier'>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <VerifierForm access_token={props.access_token} username={props.username} useremail={props.useremail}/>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Verifier;
