import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from "./auth/."

const oidcConfig = {
  authority: "https://auth.uspace-t.azure.pansa.pl/realms/uspace-intern",
  client_id: "uspace-intern-checkinverifier-spa",
  redirect_uri: "https://verify-checkin.plb.azure.pansa.pl/"
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>
);
