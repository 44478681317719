import './App.css';
import Verifier from './pages/verifier';
import { useAuth } from "./auth/.";
import {
  Box,
  Button
} from '@mui/material';

function App() {
  const auth = useAuth();

  if (auth.isLoading) {
      return <div>Loading...</div>;
  }

  if (auth.error) {
      return <div>Error: {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) { 
      return (
        <div>
          <Box sx={{ flexGrow: 1 }}>
            <div className='App-header'>         
                {auth.user?.profile.name}{" "}
              <Button
                onClick={() => void auth.removeUser()}
                color="primary">
                Logout
              </Button>
            </div>
          </Box>
          <div className="App">
            <Verifier access_token={auth.user?.access_token} username={auth.user?.profile.name} useremail={auth.user?.profile.email}/>
          </div>
        </div>

      );
  }

  if (auth.isAuthenticated === false) { 
    return (
        <div className='App-header'>
          <Button
                onClick={() => void auth.signinRedirect()}
                color="primary">
                Login
              </Button>
        </div>
    );
}
  return (
    <div className="App">
    </div>
  );
}

export default App;
