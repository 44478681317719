import { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Avatar,
  Button,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

export const CheckinListResults = ({ access_token, checkins, handleSelected, ...rest }) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = checkins.map((checkin) => checkin.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    console.log(id)
    if(typeof handleSelected === 'function') {
      console.log('funkcjon')
      handleSelected(id)
    }

    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  function mcreateGoogleLink(lon, lat) {
    return "https://www.google.com/maps/search/?api=1&query=" + lon + "," + lat;
  }

  const [values, setValues] = useState({
    mission: ''
  });

  const [visibility, setVisibility] = useState({
    details: false,
  });

  const handleGetMission = (e, missionid) => {
    e.preventDefault();
    axios.get('https://api.verify-checkin.plb.azure.pansa.pl/api/mission', {
      params: {
        'missionid': missionid,
      },
      headers: {
        'Authorization': 'Bearer ' + access_token
      }
    }, 
    ).then((response) => {
      setValues({
        ...values,
        mission: response.data
      });
      handleDetailsDialogOpen()
    })
    .catch(function (error) {

    })
  };

  const handleDetailsDialogOpen = () => {
    setVisibility({
      ...visibility,
      details: true
    });
  };

  const handleDetailsDialogClose = () => {
    setVisibility({
      ...visibility,
      details: false
    });
  };

  return (
    <>
    <Card {...rest} >
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
              <TableCell>
                  Lp.
                </TableCell>
                <TableCell padding="checkbox">
                  Raport
                </TableCell>
                <TableCell>
                  Imię i nazwisko
                </TableCell>
                <TableCell>
                  Telefon
                </TableCell>
                <TableCell>
                  Start
                </TableCell>
                <TableCell>
                  Stop
                </TableCell>
                <TableCell>
                  Lokalizacja
                </TableCell>
                <TableCell>
                  AGL(m)
                </TableCell>
                <TableCell>
                  Szczegóły
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkins.map((checkin) => (
                <TableRow
                  hover
                  key={checkin.id}
                  selected={selectedCustomerIds.indexOf(checkin.id) !== -1}
                >
                  <TableCell>
                    <i>{checkin.id + 1}.</i>
                  </TableCell>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={checkin.selected}
                      onChange={(event) => handleSelectOne(event, checkin.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    {typeof(checkin.lastname) == 'undefined'? <span></span> : <span>{checkin.lastname + ', ' + checkin.firstname}{'\n'}</span> }
                    <br></br>
                    {checkin.operator}
                  </TableCell>
                  <TableCell>
                    {checkin.phone}
                  </TableCell>
                  <TableCell>
                    {checkin.start}
                  </TableCell>
                  <TableCell>
                    {checkin.stop}
                  </TableCell> 
                  <TableCell>
                    <a href={`https://www.google.com/maps/search/?api=1&query=${checkin.geometry.coordinates[1]},${checkin.geometry.coordinates[0]}`} 
                    target="_blank" 
                    rel="noreferrer">
                      {checkin.geometry.coordinates[1] + '\n' + checkin.geometry.coordinates[0]}
                    </a>
                  </TableCell>
                  <TableCell>
                    {checkin.aglmax}
                  </TableCell>
                  <TableCell>
                  {typeof(checkin.missionid) == 'undefined'? <span>Brak misji</span> : 
                  <Button
                    color="primary"
                    onClick={e=>handleGetMission(e, checkin.missionid)}
                  >
                  <i className='fa fa-file-text-o'></i>
                  </Button>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {/* <TablePagination
        component="div"
        count={checkins.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
    </Card>

   {/* ----------------------------------------------------------------------------------------- */}

   <div>
      <Dialog open={visibility.details} onClose={handleDetailsDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",  // Set your width here
            },
          },
        }}>
        <DialogTitle>
          Informacje szczegółowe
        </DialogTitle>
        <DialogContent>
          <Card>
            <CardHeader
              title="Dane misji"
            />
          <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
               <DialogContentText>
                <b>Typ:</b> {values.mission.typ}
              </DialogContentText>
              <DialogContentText>
                <b>Nazwa:</b> {values.mission.name}
              </DialogContentText>
              <DialogContentText>
                <b>ETC:</b> {values.mission.etc}
              </DialogContentText>
              <DialogContentText>
                <b>Status:</b> {values.mission.status}
              </DialogContentText>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
        </DialogContent>
          <DialogActions>
            <Button onClick={handleDetailsDialogClose} color="primary">
              OK
            </Button>
          </DialogActions>
      </Dialog>
      </div>

    </>
  );
};

CheckinListResults.propTypes = {
  checkins: PropTypes.array.isRequired
};

export default CheckinListResults;
