import './Verifierform.css';
import { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';

import CheckinListResults from './Checkinlistresults';

export const VerifierForm = (props) => {
  const [values, setValues] = useState({
    position: '',
    start: '',
    stop: '',
    rsd: '',
    taskno: '',
    recipient: '',
    rcadress: '',
    adress: '',
    description: '',
    posformat: 'DD',
    radius: 1000,
    mission: '',
    contactphone:"",
    checkins:[]
  });

  const [visibility, setVisibility] = useState({
    modal: false,
    modal403: false,
    report_form: false,
    pdf_spinner: false,
    checkins_spinner: false
  });

  const handleSelected = (id) => {
    let checkinlist = [...values.checkins]
    let checkin = checkinlist[id]
    if (checkin.selected === false) {
      checkin.selected = true
    }
    else {
      checkin.selected = false
    }
    checkinlist[id] = checkin
    setValues({
      ...values,
      checkins: checkinlist
    });
  };

  const handleDialogOpen = () => {
    setVisibility({
      ...visibility,
      modal: true
    });
  };

  const handleDialogClose = () => {
    setVisibility({
      ...visibility,
      modal: false
    });
  };

  const handle403Open = () => {
    setVisibility({
      ...visibility,
      modal403: true
    });
  };

  const handle403Close = () => {
    setVisibility({
      ...visibility,
      modal403: false
    });
  };

  const handleReportFormOpen = () => {
    setVisibility({
      ...visibility,
      report_form: true
    });
  };

  const handleReportFormClose = () => {
    setVisibility({
      ...visibility,
      report_form: false
    });
  };

  const handlePDFSpinnerOn = () => {
    setVisibility({
      ...visibility,
      pdf_spinner: true
    });
  };

  const handlePDFSpinnerOff = () => {
    setVisibility({
      ...visibility,
      pdf_spinner: false
    });
  };

  const handleCheckinSpinnerOn = () => {
    setVisibility({
      ...visibility,
      checkins_spinner: true
    });
  };

  const handleCheckinSpinnerOff = () => {
    setVisibility({
      ...visibility,
      checkins_spinner: false
    });
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const submitHandle = (e) => {
    e.preventDefault();
    handleCheckinSpinnerOn();
    axios.get('https://api.verify-checkin.plb.azure.pansa.pl/api/checkins', {
      params: {
        'username': props.username,
        'useremail': props.useremail,
        'position': values.position,
        'start': values.start,
        'stop': values.stop,
        'radius': values.radius,
        'posformat': values.posformat
      },
      headers: {
        'Authorization': 'Bearer ' + props.access_token
      }
    }, 
    ).then((response) => {
      handleCheckinSpinnerOff();
      setValues({
        ...values,
        checkins: response.data
      });

      if (JSON.stringify(response.data) == '[]') {
        handleDialogOpen()
      }
    })
    .catch(function (error) {
      handleCheckinSpinnerOff();
      if (error.response.status === 403) {
        handle403Open();
    } else {
        handleDialogOpen();
    }
    })
  };

  const handleGetPDF = (e) => {
    e.preventDefault();
    handlePDFSpinnerOn();
    axios.post('https://api.verify-checkin.plb.azure.pansa.pl/api/pdf', {
        'checkins': values.checkins,
        'position': values.position,
        'start': values.start,
        'stop': values.stop,
        'radius': values.radius,
        'rsd': values.rsd,
        'taskno': values.taskno,
        'recipient': values.recipient,
        'rcadress': values.rcadress,
        'adress': values.adress,
        'description': values.description,
        'username': props.username,
        'useremail': props.useremail,
        'contactphone': values.contactphone
      },{
        headers: {
          'Authorization': 'Bearer ' + props.access_token
        }
      }
    ).then((response) => {
      handlePDFSpinnerOff();
      downloadPdf(response.data);
    })
  }

  function downloadPdf(base64Data) {
    var arrBuffer = base64ToArrayBuffer(base64Data);
    var newBlob = new Blob([arrBuffer], { type: "application/pdf" });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }

    var data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = "checkins.pdf";
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
}

  const handleGetCSV = (e) => {
    e.preventDefault();
    handlePDFSpinnerOn();
    axios.post('https://api.verify-checkin.plb.azure.pansa.pl/api/csv', {
        'checkins': values.checkins
      },{
        headers: {
          'Authorization': 'Bearer ' + props.access_token
        }
      }
    ).then((response) => {
      handlePDFSpinnerOff();
      downloadCsv(response.data)
    })
  }

  function downloadCsv(base64Data) {
    var newBlob = new Blob([base64Data], { type: ".csv" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
    }
    var data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = "checkins.csv";
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }

function base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

  return (
    <>
    <Container >
    <form
      autoComplete="off"
      noValidate
      onSubmit={submitHandle}
      {...props}
    >
      <Card >
        <CardHeader
          subheader="Podaj przybliżone informcje - współrzędne, promień badania oraz daty."
          title="Weryfikacja check-in"
          
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              md={8}
              xs={12}
            >
              <TextField
                fullWidth
                label="Współrzędne georaficzne"
                name="position"
                onChange={handleChange}
                required
                value={values.position}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Promień badania"
                name="radius"
                onChange={handleChange}
                required
                value={values.radius}
                variant="outlined"
                type="number"
                step="100"
                className='Form-element'
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="od"
                name="start"
                onChange={handleChange}
                required
                value={values.start}
                variant="outlined"
                type='datetime-local'
                className='Form-element'
                inputProps={{
                  style: { 
                    textAlign: 'right' 
                  }}}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="do"
                name="stop"
                onChange={handleChange}
                required
                value={values.stop}
                variant="outlined"
                type='datetime-local'
                className='Form-element'
                inputProps={{
                  style: { 
                    textAlign: 'right' 
                  }}}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            type='submit'
            disabled={visibility.checkins_spinner}
          >
            { visibility.checkins_spinner && <i className='fa fa-refresh fa-spin'></i> }
            <span>Sprawdź</span>
          </Button>
        </Box>
      </Card>
    </form>

    <Box sx={{ mt: 3 }}>
      <CheckinListResults checkins={values.checkins} access_token={props.access_token} handleSelected={handleSelected}/>
    </Box>
 
    <Grid
      container
      spacing={1}
    >
            <Grid
        item
        md={4}
        xs={12}
       ></Grid>
      <Grid
        item
        md={2}
        xs={12}
       >
    <Box sx={{ mt: 3 }}>
      <Button
        color="primary"
        variant="contained"
        type='download'
        onClick={handleGetCSV}
        disabled={visibility.pdf_spinner}
      >
        { visibility.pdf_spinner && <i className='fa fa-refresh fa-spin'></i> }
        <span>Pobierz CSV</span>
      </Button>
    </Box>
      </Grid>
      <Grid
        item
        md={2}
        xs={12}
       >
    <Box sx={{ mt: 3 }}>
      <Button
        color="primary"
        variant="contained"
        type='download'
        onClick={handleReportFormOpen}
        disabled={visibility.pdf_spinner}
      >
        { visibility.pdf_spinner && <i className='fa fa-refresh fa-spin'></i> }
        <span>Raport PDF</span>
      </Button>
    </Box>
      </Grid>
    </Grid>
    </Container>

{/* Modal------------------------------------------------------------------------------------- */} 

    <div>
      <Dialog open={visibility.modal} onClose={handleDialogClose}>
        <DialogTitle>
          Brak check'inów
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We wskazanym czasie i miejscu nikt nie zgłosił lotu BSP.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

{/* Modal403----------------------------------------------------------------------------------- */} 

    <div>
      <Dialog open={visibility.modal403} onClose={handle403Close}>
        <DialogTitle>
          Brak uprawnień do wyszukiwania check'inów
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Zgłoś się do administratora aplikacji /działu OXD/ po odpowiednie uprawnienia.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handle403Close} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

{/* ----------------------------------------------------------------------------------------- */}

    <div>
      <Dialog open={visibility.report_form} onClose={handleDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "900px",  // Set your width here
            },
          },
        }}>
        <DialogTitle>
          Raport PDF
        </DialogTitle>
        <DialogContent>
          <Card>
            <CardHeader
              subheader="Wypełnij pola raportu"
            />
          <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numer sprawy RSD"
                name="rsd"
                onChange={e=>handleChange(e)}
                required
                value={values.rsd}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Numer sprawy PAŻP"
                name="taskno"
                onChange={e=>handleChange(e)}
                required
                value={values.taskno}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
            >
              <TextField
                fullWidth
                label="Telefon kontaktowy PAŻP"
                name="contactphone"
                onChange={e=>handleChange(e)}
                required
                value={values.contactphone}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Adresat (wnioskujący)"
                name="recipient"
                onChange={e=>handleChange(e)}
                required
                value={values.recipient}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Adres wnioskującego"
                name="rcadress"
                onChange={e=>handleChange(e)}
                required
                value={values.rcadress}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Adres badanej lokalizacji"
                name="adress"
                onChange={e=>handleChange(e)}
                required
                value={values.adress}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
            <Divider />
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Opis sprawy, odpowiedź"
                name="description"
                onChange={e=>handleChange(e)}
                required
                value={values.description}
                variant="outlined"
                className='Form-element'
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
        </DialogContent>
          <DialogActions>
            <Button onClick={handleGetPDF} color="primary">
              RAPORT
            </Button>
            <Button onClick={handleReportFormClose} color="primary">
              ANULUJ
            </Button>
          </DialogActions>
      </Dialog>
    </div>
    </>
  );
};

export default VerifierForm;
